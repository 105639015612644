<div *ngIf="loading" class="box--loader">
  <img class="cross--icon" src="assets/img/cross.png" [mat-dialog-close]/>
  <div class="d-flex justify-content-center align-items-center mt-70">
    <img class="loader--image" src="assets/img/amplify-loader.gif"/>
  </div>
  <div class="txt--norm">{{ 'common.amplify.amplifying' | translate }}</div>
</div>
<div *ngIf="!loading" class="box--top">
  <img class="cross--icon" src="assets/img/cross.png" [mat-dialog-close]/>
  <div class="d-flex justify-content-center align-items-center mt-40 mb-10">
    <img src="assets/img/how-to-amplify.svg"/>
  </div>
  <div *ngIf="!desc">
    <div class="txt--title">{{ 'common.amplify.title' | translate }}</div>
    <div class="txt--desc">{{ 'common.amplify.howToAmp' | translate }}</div>
    <div class="d-flex mt-20" *ngFor="let item of stepsToAmplify; let i = index">
      <div class="item--num">{{ i+1 }}</div>
      <div class="item--txt" [innerHTML]="item"></div>
    </div>
    <div class="example--txt">
      <b>{{ 'common.amplify.example' | translate }}</b>
      <span [innerHTML]="'common.amplify.exampleTxt' | translate"></span>
    </div>
    <div class="btn--post" [mat-dialog-close]>{{ 'common.amplify.okay' | translate }}</div>
  </div>
  <div *ngIf="desc">
    <div class="txt--title">{{ 'common.amplify.amplified' | translate }}</div>
    <div class="amplify--container">
      <div class="amplify--text" *ngIf="formattedAiMsg">
        {{formattedAiMsg}}
      </div>
      <div class="d-flex btn--container">
        <div class="amplify--btn" [ngClass]="btnClass('expressive')" (click)="msgSelected('expressive')">
          {{ 'common.amplifyMsgTone.express' | translate }}
        </div>
        <div class="amplify--btn" [ngClass]="btnClass('casual')" (click)="msgSelected('casual')">
          {{ 'common.amplifyMsgTone.casual' | translate }}
        </div>
        <div class="amplify--btn" [ngClass]="btnClass('formal')" (click)="msgSelected('formal')">
          {{ 'common.amplifyMsgTone.formal' | translate }}
        </div>
      </div>
      <div class="d-flex lrg--btn--container">
        <div class="amplify--btn lrg--btn" [ngClass]="btnClass('shorter')" (click)="msgSelected('shorter')">
          {{ 'common.amplifyMsgTone.short' | translate }}
        </div>
        <div class="amplify--btn lrg--btn" [ngClass]="btnClass('emoji')" (click)="msgSelected('emoji')">
          {{ 'common.amplifyMsgTone.emoji' | translate }}
        </div>
      </div>
    </div>
    <div class="btn--post" (click)="useAmplifiedTxt()">{{ 'common.amplify.use' | translate }}</div>
  </div>
</div>