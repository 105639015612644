export default {
  address: {
    contactNumberLength: 0,
    contactNumberMinLength: 0,
    emailRequired: true,
    hidePin: true,
    hideCountry: true,
    fixCountry: '',
    pinCodeRequired: false,
    pinCodeLength: 0,
    pinCodeDigitOnly: false,
  },
  app: 'dib',
  appName: 'DIB',
  defaultLanguage: 'en',
  enableNomination: false,
  freshchat: {
    isShow: false,
    pages: ''
  },
  forgotPasswordLink: false,
  header: {
    dynamicBg: true,
    dynamicLogo: false,
    firstLogo: true,
    secondLogo: '',
    pointDetail: false,
    notification: false,
    showDepartmentInProfile: false,
  },
  hideRewardsTnC: true,
  home: {
    featuredRewards: true,
    topRewards: false,
    recommendation: false,
  },
  languageAvailable: ['en'],
  languageLabel: {
    en: 'English',
  },
  leftNavigate: ['home', 'appreciate', 'feeds', 'rewards'],
  loginTermsCondition: true,
  isSSOLoginSupport: true,
  modules: {
    home: true,
    appreciate: true,
    feeds: true,
    rewards: true,
    myWallet: false,
    settings: false,
    pointsHistory: false,
    cart: false,
  },
  mobileTopup: {
    mobileNumberMaxDigit: 14,
    mobileNumberMinDigit: 9,
  },
  rating: false,
  redeemedSection: '',
  registerNewAccount: false,

  sideCarousel: [
    {
      img: 'assets/img/slider/1.jpg',
    },
    {
      img: 'assets/img/slider/2.jpg',
    },
    {
      img: 'assets/img/slider/3.jpg',
    },
  ],
  settings: {
    hideMyAppreciationMessagesToggle: false,
    makeMyBirthdayPublicToggle: false,
    makeMyAnniversaryPublicToggle: false,
    makeMyProfilePublicToggle: false,
    passwordChangeShow: false,
    passwordChangeStyle: 'float',
  },
  showFooter: '1',
  showUsername: false,
  thankMessageLeftBox: true,
  footer: {
    logoLink: '',
    privacyLink: '',
    tncLink: 'https://rewardz.sg/privacy-policy.html',
    userGuide: 'https://cerrapoints.ae/media/attachment/1707909795.pdf',
    showContactUS: false,
  },
};
