import { Component } from '@angular/core';
import {environment} from "src/environments/environment";

@Component({
  selector: 'app-filler',
  templateUrl: environment?.components?.common?.filler?.templateUrl,
  styleUrls: ['./filler.component.scss'],
})
export class FillerComponent {
}
