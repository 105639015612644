<!-- <div class="row g-0 my-4 box__blue"> -->
  <!-- <div class="col d-flex flex-row align-items-center box__summary">
    <div class="box--img-wrapper">
      <div class="box--img">
        <div #ref><ng-content></ng-content></div>
        <img
          *ngIf="!ref.hasChildNodes()"
          class="img-fluid"
          src="assets/img/flex-dollars.png"
          width="26px"
          height="16px"
        />
      </div>
    </div>
    <div class="d-flex flex-column">
      <app-spinner *ngIf="loading"></app-spinner>
      <div class="text__point">{{ flexDollarsAvailable | number }}</div>
      <div class="text__desc">
        {{ 'common.pointsAvailableForRedemption' | translate }}
      </div>
    </div>
  </div> -->
  <!-- <div class="col d-flex flex-row box__detail1">
    <div class="box--img-wrapper">
      <div class="d-flex flex-column box__detail2 p-3">
        <div class="text__point-detail">{{ flexDollarsEarned | number }}</div>
        <div class="text__desc-detail">
          {{ 'common.totalPointsEarned' | translate }}
        </div>
      </div>
    </div>
    <div class="box--img-wrapper">
      <div class="d-flex flex-column box__detail2 p-3">
        <div class="text__point-detail">{{ flexDollarsRedeemed | number }}</div>
        <div class="text__desc-detail">
          {{ 'common.totalPointsRedeemed' | translate }}
        </div>
      </div>
    </div>
  </div> -->
  <!-- old -->
  <!-- <div class="row g-0 mt-2 mb-2">
    <div class="col d-flex flex-row">
      <img class="img-fluid img--wallet" src="assets/img/wallet-1.png" />
      <div class="d-flex flex-column ms-3">
        <div class="d-flex flex-row align-items-center">
          <div class="text2">
            <app-spinner *ngIf="loading"></app-spinner>
            {{ flexDollarsAvailable | number }}
          </div>
        </div>
        <div class="text__desc-detail">{{ "common.pointsAvailableForRedemption" | translate }}</div>
      </div>
      <div class="col d-flex flex-row box__detail1">
        <div class="box--img-wrapper">
          <div class="d-flex flex-column box__detail2 p-3" (click)="updateTransactions('earned', 'all')">
            <div class="text__point-detail">{{ flexDollarsEarned | number }}</div>
            <div class="text__desc-detail">
              {{ 'common.totalPointsEarned' | translate }}
            </div>
          </div>
        </div>
        <div class="box--img-wrapper">
          <div class="d-flex flex-column box__detail2 p-3" (click)="updateTransactions('redeemed', 'all')">
            <div class="text__point-detail">{{ flexDollarsRedeemed | number }}</div>
            <div class="text__desc-detail">
              {{ 'common.totalPointsRedeemed' | translate }}
            </div>
          </div>
        </div>
        <div class="box--img-wrapper">
          <div class="d-flex flex-column box__detail3 p-3" (click)="updateTransactions('expiring', 'all')">
            <div class="text__point-detail">{{ pointsExpiringThisMonth.points | number }}</div>
            <div class="text__desc-detail">
              {{ 'common.totalPointsExpiring' | translate }} {{pointsExpiringThisMonth.expiry_date | date: 'dd MMM yyyy'}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
<!-- </div> -->

<!-- new code -->
<div class="box1">
  <div class="row g-0">
    <div class="col text1">{{ "common.myPoints" | translate }}</div>
  </div>
  <div class="row mt-4">
    <div class="col d-flex">
      <img class="img-fluid img--wallet" src="assets/img/wallet-1.png" />
      <div class="available--points ms-3">
          <div class="text2">
            <app-spinner *ngIf="loading"></app-spinner>
            {{ flexDollarsAvailable | number }}
          </div>
          <div class="point--details">
            <!-- <img class="ms-2 img--reload" src="assets/img/reload.png" width="10.5px" height="10.5px"
            /> -->
            &nbsp;&nbsp;<div class="text__desc-detail">{{ "common.pointsAvailableForRedemption" | translate }}</div>
          </div>
      </div>
      </div>
      <div class="col box__detail1">
        <div class="box--img-wrapper"  (click)="updateTransactions('earned', 'all')">
          <div class="d-flex flex-column box__detail2 p-3">
            <div class="text__point-detail">{{ flexDollarsEarned | number }}</div>
            <div class="text__desc-detail">
              {{ 'common.totalPointsEarned' | translate }}
            </div>
          </div>
        </div>
        <div class="box--img-wrapper" (click)="updateTransactions('redeemed', 'all')">
          <div class="d-flex flex-column box__detail2 p-3">
            <div class="text__point-detail">{{ flexDollarsRedeemed | number }}</div>
            <div class="text__desc-detail">
              {{ 'common.totalPointsRedeemed' | translate }}
            </div>
          </div>
        </div>
        <div *ngIf="!!expiryPoint" class="box--img-wrapper" (click)="updateTransactions('expiring', 'all')">
          <div class="d-flex flex-column box__detail3 p-3">
            <div class="text__point-detail">{{ pointsExpiringThisMonth.points | number }}</div>
            <div class="text__desc-detail">
              {{ 'common.totalPointsExpiring' | translate }} 
              {{ showYearlyExpiringPoints ? (pointsExpiringThisMonth.expiry_date | date : "yyyy") : (pointsExpiringThisMonth.expiry_date | date : "dd MMM yyyy") }}
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
