export enum ShippingAddressDirection {
  physicalAddress = 'physicalAddress',
  sfStoreOrSfLocker = 'sfStoreOrSfLocker',
}

export enum ShippingAddressMode {
  physicalAddress = 'physicalAddress',
  sfStore = 'sfStore',
  sfLocker = 'sfLocker',
}

export enum PrivacyType {
  public = 20,
  team = 10,
  private = 30,
}

export enum RedemptionType {
  physicalProduct = 'physical_product',
  eVoucher = 'e_voucher',
  flash = 'flash',
  flashPassword = 'flash_password',
  mobileTopup = 'mobile_topup',
  electricityTopup = 'electricity_topup',
  qwikcilver = 'qwikcilver',
  microRedemptions = 'micro_redemptions',
}

export enum VoucherStatus {
  NA = 0,
  Saved = 1,
  Redeemed = 2,
  Received = 3,
  Expired = 4,
  InProcess = 5,
  Shipped = 6,
  OutOfStock = 7,
  ReadyForPickup = 8,
  Used = 9,
  MarkAsUsed = 10,
  LinkOpened = 11,
}

export enum FeedType {
  all = 'all',
  appreciation = 'appreciation',
  nomination = 'nomination',
}

export enum PostType {
  all = 0,
  post = 1,
  poll = 2,
  appreciation = 6,
  nomination = 7,
  greeting = 9,
}

export enum ReactionTypeEnum {
  like = 0,
  love = 3,
  clap = 6,
  support = 2,
  celebrate = 1,
  all = 7,
}

export enum NominationStatus {
  submitted = 0,
  inReviewApprover1 = 1,
  inReviewApprover2 = 2,
  approved = 3,
  rejected = 4,
  draft = 5,
  infoRequested = 6,
  resubmitted = 7,
}

export enum NominationStatusWord {
  all = '',
  pending = 'pending',
  approved = 'approved',
  rejected = 'rejected',
  draft = 'draft',
}

export enum CalendarType {
  announcement = 'announcement',
  event = 'event',
  greeting = 'greeting',
}

export enum GreetingType {
  anniversary = 'Anniversary',
  birthday = 'Birthday',
}

export enum AmplifyMsgTone {
  expressive = 'Expressive',
  casual = 'One paragraph casual tone',
  formal = 'Formal',
  shorter = 'Shorter',
  emoji = 'Append a few relevant emojis to the end of the paragraph or within sentences to enhance the emotion of the message',
}

export enum PriorTill {
  always = 0,
  oneDay = 1,
  oneWeek = 7,
  oneMonth = 30,
}

export enum NotificationObjectType {
  posts = 'Posts',
  pointAllocation = 'Point allocation',
  nomination = 'Nomination',
  reward = 'Reward',
  announcement = 'Announcement',
  event = 'Event',
  anniversary = 'Anniversary',
  birthday = 'Birthday',
  greeting = 'Greeting',
  survey = 'Survey',
  none = 'None',
}

export enum NotificationRedirectScreen {
  received = 'Received',
  given = 'Given',
  approvals = 'Approvals',
  rejected = 'Rejected',
  post = 'Post',
  poll = 'Poll',
}

export enum ReceiptStatus {
  processing = 0,
  approved = 1,
  rejected = 2,
  infoRequested = 3,
  pending = 4,
  autoRejected = 5,
  onHold = 6,
}

export enum LanguageMode {
  switch,
  textDropdown,
  iconDropdown,
}

export enum NotificationStatusE {
  unread,
  read,
}

export enum FileUploadCount {
  single = 'single',
  multiple = 'multiple',
}

export enum NominationCategoryReviewerType {
  none = 0,
  nomineeManager = 1,
  nominatorManager = 2,
  singleLevelUsers = 3,
}

export enum NominationCategoryReviewerLevel {
  none = 0,
  level1 = 1,
  level2 = 2,
  level3 = 3,
  level4 = 4,
}

export enum NominationCategoryAutoAction {
  approve = 1,
  reject = 2,
  escalation = 3,
}

export enum NominationCategoryStatus {
  active = 'Active',
  closed = 'Closed',
  expired = 'Expired',
}

export enum NominationQuestionTypeStr {
  oneLinerTextbox = 'One liner textbox',
  multipleLineText = 'Multiple line text',
  choice = 'Choice',
  document = 'Document',
}

export enum NominationQuestionTypeNum {
  multipleLineText = 1,
  oneLinerTextbox = 2,
  document = 3,
  choice = 4,
}

export enum NominationHistoryStatus {
  approved = 'Approved',
  pending = 'Pending',
  rejected = 'Rejected',
  none = '',
}

export enum RatingProcess {
  notApplicable = 'Not Applicable',
  awaitingStart = 'Awaiting Start',
  inProgress = 'In Progress',
  completed = 'Completed',
}

export enum RewardTiers {
  Bronze = 1,
  Silver = 2,
  Diamond = 3,
}

export enum RewardTiersColors {
  Bronze = '#cd7f32',
  Silver = '#a2a9b0',
  Diamond = '#1685b4',
  none = '#151515',
}

export enum RsvpState {
  NotAttending = 'not_attending',
  Attending = 'attending',
  Attended = 'attended',
}

export enum AvailableFor {
  all,
  reviewOnly,
  ratingOnly,
}
