import { EventEmitter, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { showInfo } from '../utility/common-logic';

@Injectable({
  providedIn: 'root',
})
export class LangService {
  showNotif = false;
  langUpdated: EventEmitter<void> = new EventEmitter();
  langs = environment.settings.languageAvailable;
  languageLabel = environment.settings.languageLabel;
  defaultLanguage = environment.settings.defaultLanguage;
  enforceDefaultLanguage = environment.settings.enforceDefaultLanguage;

  constructor(
    public translate: TranslateService,
    private toastr: ToastrService
  ) {
    translate.addLangs(this.langs);
    let lang = localStorage.getItem('lang');
    if (!lang || !this.langs.includes(lang)) {
      const browserLang = this.translate.getBrowserLang();
      lang =
        browserLang &&
        this.langs.includes(browserLang) &&
        !this.enforceDefaultLanguage
          ? browserLang
          : this.defaultLanguage;
    }
    localStorage.setItem('lang', lang);
    // English can be set as default, but not all clients may use it
    translate.setDefaultLang(this.defaultLanguage);
    translate.use(lang);
  }

  get langsAvailable() {
    return [...this.translate.getLangs()];
  }

  get lang() {
    return this.translate.currentLang;
  }

  set lang(l: string) {
    if (this.lang === l) {
      return;
    }
    // if lang is not there in the list of languages, fallback to default
    if (!this.langs.includes(l)) {
      l = this.defaultLanguage;
    }
    localStorage.setItem('lang', l);
    this.translate.use(l);
    this.langUpdated.emit();
    if (this.showNotif) {
      showInfo(
        this.translate.instant('msg.lang', { l: this.langLabel(l) }),
        this.toastr,
        this.translate
      );
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }

  langLabel(l: string) {
    return this.languageLabel[l];
  }
}
