import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { setAuthData } from 'src/app/state/auth-data/auth-data.actions';
import { AuthData } from 'src/app/utility/model/token-auth';
import { Router } from '@angular/router';
import { clearLocalStorage } from 'src/app/utility/local-storage';
import {
  getFullName,
  getMyNameAcronym,
  getNameAcronym,
  getProfPic,
  showError,
} from '../../../utility/common-logic';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/services/api-services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
})
export class PanelComponent implements OnInit {
  authData: Partial<AuthData> = {};
  modules = environment.settings.modules;
  panel = environment.settings.panel;
  admin = environment.url.admin;
  isBSTLApp = environment.settings.app === 'bstl';
  myFeeds = environment.settings.myFeeds;
  freshchat = environment.settings.freshchat;

  constructor(
    private authDataStore: Store<{ authData: AuthData }>,
    private router: Router,
    private api: ApiService,
    private translate: TranslateService,
    private toastr: ToastrService,
  ) {}

  async ngOnInit() {
    this.authDataStore
      .select('authData')
      .subscribe(async (res: Partial<AuthData>) => (this.authData = res));
    if (this.modules.feeds) {
      await this.api
        .getUserFeed('approvals')
        .then((res) => {
          this.authDataStore?.dispatch(
            setAuthData({
              approvalsCounter: res?.approvals_count,
              showApprovals: res.show_approvals,
            }),
          );
        })
        .catch((err) => showError(err, this.toastr, this.translate));
    }
  }

  get profPic() {
    return getProfPic(this.authData);
  }

  get acronym() {
    if (this.isBSTLApp) {
      return getNameAcronym(
        this.authData?.tokenAuth?.user_details?.department_name,
      );
    } else {
      return getMyNameAcronym(this.authData);
    }
  }

  get fullName() {
    return getFullName(this.authData);
  }

  async signOut() {
    if (this.freshchat.isShow && !!(window as any).fcWidget) {
      (window as any).fcWidget.hide();
    }
    clearLocalStorage();
    this.api.clearCacheStorage();
    this.router.navigate(['/login']);
  }

  get actionClass() {
    let isShow =
      this.panel.link || this.authData?.tokenAuth?.user_details?.is_staff;
    isShow =
      isShow ||
      this.modules.feeds ||
      this.authData?.tokenAuth?.organization_settings?.enable_nominations ===
        1 ||
      this.modules.settings ||
      this.panel.support;
    return isShow ? 'show' : '';
  }
}
