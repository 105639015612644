import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { ImgFile } from '../../utility/model/images';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface DialogData {
  dialogMode: boolean;
  images?: ImgFile[];
  only1Image?: boolean;
}

@Component({
  selector: 'app-add-image',
  templateUrl: './add-image.component.html',
  styleUrls: ['./add-image.component.scss'],
})
export class AddImageComponent implements OnInit {
  @Input() isAddPostPoll = '';
  @Input() isOpen = false;
  @Output() goBack = new EventEmitter();
  imageChangedEvent: any = '';
  uploadedFiles: ImgFile[] = [];
  croppedImage = '';
  currentProcessingImg = 0;
  canvasRotation = 0;
  transform: ImageTransform = {};
  @Output() imagesChange = new EventEmitter<ImgFile[]>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogRef: MatDialogRef<AddImageComponent>,
  ) {}

  ngOnInit() {
    (document.getElementById('uploader') as HTMLElement).click();
  }

  get disabled() {
    return !this.uploadedFiles.length;
  }

  get disabledClass() {
    return this.disabled ? 'disabled' : '';
  }

  @HostListener('document:keyup.escape', ['$event']) onKeydownHandler() {
    if ((this.isAddPostPoll && this.isOpen) || !this.isAddPostPoll) {
      if (!!this.croppedImage) {
        this.croppedImage = '';
        this.currentProcessingImg = 0;
        this.imageChangedEvent = '';
      } else if (!!this.uploadedFiles.length) {
        this.uploadedFiles.pop();
      } else {
        this.goBack.emit();
        if (this.data?.dialogMode) {
          this.dialogRef.close();
        }
      }
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {}

  cropperReady() {}

  loadImageFailed() {}

  cropImage(img: ImgFile) {
    this.croppedImage = img.imgBase64;
    this.currentProcessingImg = img.imgNo;
    const imgObj = this.uploadedFiles.find((x) => x.imgNo === img.imgNo);
    this.imageChangedEvent = { target: { files: [imgObj.imgFile] } };
  }

  fileChangeEvent(event: any): void {
    const uploadedFilesCount = this.uploadedFiles.length;
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        this.uploadedFiles.push({
          imgNo: i + uploadedFilesCount + 1,
          imgBase64: String(reader.result),
          imgFile: file,
          type: 'image'
        });
        this.cropImage(this.uploadedFiles[0]);
      };
    }
  }

  saveCroppedImage() {
    const imgObj = this.uploadedFiles.find(
      (x) => x.imgNo === this.currentProcessingImg,
    );
    imgObj.imgBase64 = this.croppedImage;
    this.imageChangedEvent = null;
    const nextImgObj = this.uploadedFiles.find(
      (x) => x.imgNo === this.currentProcessingImg + 1,
    );
    if (nextImgObj != undefined) {
      this.cropImage(nextImgObj);
    } else {
      this.cropImage(imgObj);
    }
  }

  resetImage() {
    this.canvasRotation = 0;
    this.transform = {};
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH,
    };
  }

  async proceed() {
    if (!this.uploadedFiles.length) {
      return;
    }
    this.imagesChange.emit(this.uploadedFiles);
  }
}
