<div class="dropdown">
  <div
    class="user-profile"
    (click)="toggleStyleDisplay(style)"
    (clickOutside)="style.display = 'none'"
  >
    <ng-container *ngIf="!profPic">
      <div *ngIf="!!acronym" class="txt--acronym">{{ acronym }}</div>
      <img
        *ngIf="!acronym"
        class="img-fluid default"
        src="assets/img/default-user.png"
      />
    </ng-container>
    <img
      *ngIf="profPic"
      class="img-fluid"
      [src]="profPic"
    />
  </div>
  <app-panel class="dropdown-content" [ngStyle]="style"></app-panel>
</div>
