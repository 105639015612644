import axios from 'axios';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { bypassedApisWithEndpoint } from '../utility/static-data/bypassed-apis';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AxiosService {
  constructor(
    private router: Router,
    private translate: TranslateService,
  ) {}

  configureAxios() {
    const bypassedApis = bypassedApisWithEndpoint;

    axios.interceptors.request.use(
      (req) => {
        const token = localStorage.getItem('token-auth') as string;
        const isBypassedUrl = bypassedApis.some(url => req.url.includes(url)) || bypassedApis.includes(req.url);
        if (isBypassedUrl) {
          return req;
        } else if (!!token) {
          return req;
        }
        return Promise.reject(
          new Error(this.translate.instant('authentication.expiredSession')),
        );
      },
      (err) => {
        return Promise.reject(err);
      },
    );

    // Add a response interceptor
    axios.interceptors.response.use(
      (response) => {
        // Do something with successful responses
        return response;
      },
      (error) => {
        // Handle errors
        if (error?.response?.status === 401) {
          localStorage.removeItem('token-auth');
          this.router.navigate(['/login']);
        }
        return Promise.reject(error);
      },
    );
  }
}
