import { Cart } from './../utility/model/cart';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import dayjs from 'dayjs';
import { Store } from '@ngrx/store';
import { AuthData } from '../utility/model/token-auth';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  authData: Partial<AuthData> = {};
  spinner = false;
  selectedLan = new BehaviorSubject('');
  cartCount = new BehaviorSubject(0);
  cartDetails = new BehaviorSubject('');
  cartItems = new BehaviorSubject<Cart[]>([]);
  rewardQty = new BehaviorSubject(1);
  cartStatus = new BehaviorSubject('');
  registerData = new BehaviorSubject([]);

  constructor(private authDataStore: Store<{ authData: AuthData }>) {
    this.authDataStore
      .select('authData')
      .subscribe((res: Partial<AuthData>) => (this.authData = res));
  }

  isMobile() {
    return /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }

  isLogin() {
    if (!!this.authData?.tokenAuth?.token) {
      return dayjs().isSame(this.authData?.tokenDate, 'day');
    }
    return false;
  }

  setRegisterData(data:any){
    this.registerData.next(data)
  }

  getRegisterData() {
    return this.cartDetails.asObservable()
  }
}
