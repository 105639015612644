import { AuthData } from '../../utility/model/token-auth';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, UrlSegment } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss'],
})
export class TopMenuComponent implements OnInit {
  authData: Partial<AuthData> = {};
  modules = environment.settings.modules;
  to: string;

  constructor(
    private authDataStore: Store<{ authData: AuthData }>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.authDataStore
      .select('authData')
      .subscribe((res: Partial<AuthData>) => (this.authData = res));
    this.activatedRoute.url.subscribe((url: UrlSegment[]) => {
      this.to = url.length > 0 ? url[0].path : this.router.url.split('/')[1];
    });
  }

  get isCalendarEnabled() {
    return (
      this.authData?.dashboard?.show_events !== 0 ||
      this.authData?.dashboard?.show_greetings !== 0
    );
  }

  is(
    to:
      | 'home'
      | 'my-feed'
      | 'rewards'
      | 'my-wallet'
      | 'calendar'
      | 'settings'
      | 'appreciate'
      | 'point-history',
  ) {
    return (
      (to === 'my-wallet' && to === this.router.url.split('/')[2]) ||
      this.to === to
    );
  }

  selectedClass(
    to:
      | 'home'
      | 'my-feed'
      | 'rewards'
      | 'my-wallet'
      | 'calendar'
      | 'settings'
      | 'appreciate'
      | 'point-history',
  ) {
    return this.is(to) ? 'selected' : '';
  }

  fontClass(to: string) {
    return this.to === to ? 'font-selected' : 'font-unselected';
  }
  onClickTopMenu(
    menu:
      | 'home'
      | 'my-feed'
      | 'rewards'
      | 'my-wallet'
      | 'calendar'
      | 'settings'
      | 'appreciate'
      | 'point-history',
  ) {
    sessionStorage.setItem('selected-topMenu', menu);
    this.to = menu;
  }
}
