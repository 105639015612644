import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthData } from 'src/app/utility/model/token-auth';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api-services/api.service';
import { SharedService } from 'src/app/services/shared.service';
import { OrderProcessComponent } from '../order-process/order-process.component';
import { environment } from 'src/environments/environment';
import { PaymentIntentResp } from 'src/app/utility/model/paymentIntent';
import { DOCUMENT } from '@angular/common';
import { checkRealMoneyisNull, showError, showWarning } from 'src/app/utility/common-logic';
import { TranslateService } from '@ngx-translate/core';
import { ShippingAddressMode } from 'src/app/utility/enum';
import { OrderDetail } from 'src/app/utility/model/delivery-fee';

interface DialogData {
  cartDetails: any;
  addressChoice: any;
  cartTotalPointsPayable: number;
  cartDeliveryFee: number;
}

@Component({
  selector: 'app-order-confirmation-with-cart',
  templateUrl: './order-confirmation-with-cart.component.html',
  styleUrls: ['./order-confirmation-with-cart.component.scss'],
})
export class OrderConfirmationWithCartComponent implements OnInit {
  authData: Partial<AuthData> = {};
  cartDetails: any;
  addressChoice: any;
  onCartDataChange = new EventEmitter();
  @Output() receivedCartChanges = new EventEmitter<void>();
  cartTotalPointsPayable: number;
  cartDeliveryFee: number;
  deliveryFee: number | null | undefined = 0;
  adminFee: number | null | undefined = 0;
  adminFeePercentage: number | string | null | undefined = 0;
  amountFromCalculatePoint: number | string | null | undefined = 0;
  balancePointsNeededCart: number | string | null | undefined = 0;
  points: any;

  constructor(
    private authDataStore: Store<{ authData: AuthData }>,
    private dialogRef: MatDialogRef<OrderConfirmationWithCartComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private api: ApiService,
    private shared: SharedService,
    public dialog: MatDialog,
    @Inject(DOCUMENT) private document: Document,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {}

  async ngOnInit() {
    this.authDataStore
      .select('authData')
      .subscribe((res: Partial<AuthData>) => (this.authData = res));
    this.cartDetails = this.data.cartDetails;
    this.addressChoice =
      this.data?.addressChoice != null ? this.data?.addressChoice : null;
    this.cartTotalPointsPayable = this.data?.cartTotalPointsPayable
      ? this.data?.cartTotalPointsPayable
      : null;
    this.cartDeliveryFee = this.data?.cartDeliveryFee
      ? this.data?.cartDeliveryFee
      : null;
    // TODO: Either we set the above values or call the below API, not sure why we are doing both
    setTimeout(() => {
      this.calculatePoints();
    }, 500);
  }

  get addressDisplay1() {
    if (
      this.data?.addressChoice?.mode === ShippingAddressMode.physicalAddress
    ) {
      return (
        this.data?.addressChoice?.pa?.address_line_1 +
        ' ' +
        this.data?.addressChoice?.pa?.address_line_2
      );
    } else if (this.data?.addressChoice?.mode === ShippingAddressMode.sfStore) {
      return 'SF Store Self-Collection';
    } else {
      return 'SF Locker Self-Collection';
    }
  }

  get addressDisplay2() {
    if (
      this.data?.addressChoice?.mode === ShippingAddressMode.physicalAddress
    ) {
      return this.data?.addressChoice?.pa?.contact_number;
    } else if (this.data?.addressChoice?.mode === ShippingAddressMode.sfStore) {
      return this.data?.addressChoice?.sfStore;
    } else if (
      this.data?.addressChoice?.mode === ShippingAddressMode.sfLocker
    ) {
      return this.data?.addressChoice?.sfLocker;
    }
    return '';
  }

  get country() {
    if (
      this.data?.addressChoice?.mode === ShippingAddressMode.physicalAddress
    ) {
      return this.data?.addressChoice?.pa?.country;
    }
    return this.authData?.tokenAuth?.organization_details?.country;
  }

  async onShowCartSummary(data: any) {
    this.cartDetails = data;
    await this.calculatePoints();
    this.onCartDataChange.emit(this.data);
  }

  get addressType() {
    if (this.data?.addressChoice?.mode === ShippingAddressMode.sfStore) {
      return 'sf-store';
    } else if (
      this.data?.addressChoice?.mode === ShippingAddressMode.sfLocker
    ) {
      return 'sf-locker';
    }
    return 'address';
  }

  get sfShippingId() {
    if (this.data?.addressChoice?.mode === ShippingAddressMode.sfStore) {
      return this.data?.addressChoice?.sfStore;
    } else if (
      this.data?.addressChoice?.mode === ShippingAddressMode.sfLocker
    ) {
      return this.data?.addressChoice?.sfLocker;
    }
    return null;
  }

  get contactNumber() {
    if (
      this.data?.addressChoice?.mode === ShippingAddressMode.physicalAddress
    ) {
      return this.data?.addressChoice?.pa?.contact_number;
    }
    if (
      this.data?.addressChoice?.mode === ShippingAddressMode.sfStore ||
      this.data?.addressChoice?.mode === ShippingAddressMode.sfLocker
    ) {
      return this.data?.addressChoice?.contactNumber;
    }
    const phoneNumber = this.authData?.tokenAuth?.user_details?.phone_number;
    return !phoneNumber ? '-' : phoneNumber;
  }

  get shippingId() {
    if (this.data.addressChoice?.mode === ShippingAddressMode.physicalAddress) {
      return this.data.addressChoice.pa?.pk;
    } else if (this.data.addressChoice?.mode === ShippingAddressMode.sfStore) {
      return this.data.addressChoice?.sfStore;
    } else {
      return this.data.addressChoice?.sfLocker;
    }
  }

  async onPlaceOrder() {
    this.shared.spinner = true;
    const postData = {
      address: this.data?.addressChoice?.pa?.pk || null,
      shipping_id: this.sfShippingId,
      address_type: this.addressType,
      contact_number: this.contactNumber,
    };
    await this.api
      .redeemCart(postData)
      .then((res: any) => {
        this.dialogRef.close();
        this.dialog.open(OrderProcessComponent, {
          maxWidth: '90vw',
          maxHeight: '90vh',
          disableClose: true,
          data: {
            cartDetails: this.cartDetails,
            responseData: res,
            addressChoice: this.addressChoice,
            address: this.data?.addressChoice?.pa?.pk || null,
            shippingId: this.sfShippingId,
            addressType: this.addressType,
            contactNumber: this.contactNumber,
            pointsDeducted: this.totalPointsPayable,
          },
        });
      })
      .catch((err) => showError(err, this.toastr, this.translate))
      .finally(() => (this.shared.spinner = false));
  }

  async calculatePoints() {
    this.shared.spinner = true;
    const postData = {
      gateway: 'ntt',
      is_cart: true,
      shipping_id: this.shippingId,
    };
    await this.api
      .calculatePoints(postData)
      .then((res: any) => {
        this.points = res;
        this.cartDeliveryFee = res.delivery_fee;
        this.cartTotalPointsPayable = res.points;
        this.adminFee = Number(res.service_charge);
        this.adminFeePercentage = res.service_charge_percentage;
        this.amountFromCalculatePoint = res.total_amount;
        this.balancePointsNeededCart = res.amount;
      })
      .catch((err) => showError(err, this.toastr, this.translate))
      .finally(() => (this.shared.spinner = false));
  }

  get isOnlinePaymentCart() {
    return (
      this.cartDetails?.amount_payable + Number(this.cartDeliveryFee) >
      this.cartDetails?.user_points
    );
  }

  get totalAmountPayable(): number {
    return Number(this.amountFromCalculatePoint);
  }

  get totalPointsPayable(): number {
    return this.points?.total_amount > 0
      ? this.points?.total_amount
      : this.points?.points;
  }

  async onProceedPayment() {
    const payment_success_url =
      environment.url.basePath + 'my-cart/payment/success';
    const payment_failed_url =
      environment.url.basePath + 'my-cart/payment/failed';
    const amount = this.isOnlinePaymentCart
      ? this.totalAmountPayable
      : this.cartDetails?.amount_payable;
    const body = {
      amount: amount,
      points: this.cartTotalPointsPayable,
      organization: this.authData?.tokenAuth?.user_details?.organization_pk,
      gateway: 'ntt',
      currency: this.authData?.tokenAuth?.currency,
      payment_success_url: payment_success_url,
      payment_failed_url: payment_failed_url,
      redirect_url: payment_success_url,
      admin_fee: this.adminFee,
      delivery_fee: this.cartDeliveryFee,
      is_redemption: 1,
      user: this.authData?.tokenAuth?.user_details?.pk,
    };
    await this.api
      .paymentCheckout(body)
      .then((res: PaymentIntentResp) => {
        const orderDetails: Partial<OrderDetail> = {
          cartDetails: this.cartDetails,
          redeemableDetails: {
            isOnlinePayment: true,
            totalAmountPayable: amount,
            remainingPoints: this.cartDetails?.user_points,
            admin_fee: this.adminFee,
            delivery_fee: this.cartDeliveryFee,
          },
          user_data: {
            addressChoice: this.data?.addressChoice,
            address: this.data?.addressChoice?.pa?.pk || null,
            shippingId: this.sfShippingId,
            addressType: this.addressType,
            contactNumber: this.contactNumber,
          },
        };
        localStorage.setItem('orderDetails', JSON.stringify(orderDetails));
        if (res.payment_link) {
          this.document.location.href = res.payment_link;
        }
        if (res.payment_failed_url) {
          this.document.location.href = res.payment_failed_url;
        }
      })
      .catch((err) => {
        showWarning(err, this.toastr, this.translate);
      })
      .finally(() => (this.shared.spinner = false));
  }

  get hideIfRealMoneyisNull() {
    return checkRealMoneyisNull(this.cartDetails);
  }
}


