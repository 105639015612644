import { Component, EventEmitter, Output } from '@angular/core';
import { ApiService } from '../../services/api-services/api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { clearLocalStorage } from '../../utility/local-storage';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef } from '@angular/material/dialog';
import { showError, showSuccess } from 'src/app/utility/common-logic';

@Component({
  selector: 'app-password-change-modal',
  templateUrl: './password-change-modal.component.html',
  styleUrls: ['./password-change-modal.component.scss'],
})
export class PasswordChangeModalComponent {
  form = this.fb.group({
    old_password: ['', Validators.required],
    new_password: ['', Validators.required],
    confirm_new_password: ['', Validators.required],
  });
  types = ['password', 'password', 'password'];
  loading = false;
  errMessage = '';
  @Output() close = new EventEmitter<void>();

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private toastr: ToastrService,
    private router: Router,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<PasswordChangeModalComponent>,
  ) { }

  get valid() {
    return this.form.valid &&
      this.form.value.new_password === this.form.value.confirm_new_password &&
      this.form.value.new_password.length >= 8
      ? ''
      : 'disabled';
  }

  async onSubmit() {
    if (this.valid === '') {
      this.loading = true;
      await this.api.changePassword(this.form.value).then(async () => {
        clearLocalStorage();
        showSuccess('msg.password.saved', this.toastr, this.translate, true);
        this.dialogRef.close();
        await this.router.navigate(['/login']);
      }).catch(err => this.errMessage = showError(err, this.toastr, this.translate)).finally(() => this.loading = false);
    }
  }

  switch(index: number) {
    if (this.types[index] === 'password') {
      this.types[index] = 'text';
    } else {
      this.types[index] = 'password';
    }
  }

  get isModalClass() {
    return 'add-padding';
  }

  onClose() {
    this.close.emit();
    this.dialogRef.close();
  }
}
