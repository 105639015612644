import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/api-services/api.service';
import { AmplifyMsgTone } from 'src/app/utility/enum';
import { environment } from 'src/environments/environment';

interface DialogData {
  single: boolean;
  desc?: string;
  coreValue: any;
}

@Component({
  selector: 'app-amplify-modal',
  templateUrl: './amplify-modal.component.html',
  styleUrls: ['./amplify-modal.component.scss'],
})
export class AmplifyModalComponent implements OnInit {
  loading = false;
  desc = '';
  companyCoreValue = '';
  aiMessage = '';
  formattedAiMsg = '';
  btnSelected = 'expressive';
  @Output() descUpdate = new EventEmitter();
  languageLabel = environment.settings.languageLabel;
  lang: string;
  amplifyMsgTones: any = AmplifyMsgTone;
  msgTone = '';
  stepsToAmplify = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private api: ApiService,
    private dialogRef: MatDialogRef<AmplifyModalComponent>,
    private translate: TranslateService,
  ) {}

  async ngOnInit() {
    this.lang =
      this.languageLabel[
        localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en'
      ];
    if (this.data?.coreValue) {
      this.companyCoreValue = this.data?.coreValue?.name;
    }
    if (this.data?.desc) {
      this.desc = this.data?.desc;
      this.getAmplifiedText();
    }
    let step1 = this.translate.instant('common.amplify.step1');
    let step2 = this.translate.instant('common.amplify.step2');
    let step3 = this.translate.instant('common.amplify.step3');
    let step4 = this.translate.instant('common.amplify.step4');
    this.stepsToAmplify.push(step1, step2, step3, step4);
  }

  async getAmplifiedText() {
    this.loading = true;
    let params = {
      userInputText: this.desc,
      language: this.lang,
    };
    if (this.companyCoreValue !== '') {
      params['companyCoreValue'] = this.companyCoreValue;
    }
    await this.api.getAmplifyTxt(params).then((res) => {
      if (res) {
        this.aiMessage = res?.ai_message;
        this.formattedAiMsg = this.aiMessage;
        this.loading = false;
        this.btnSelected = 'expressive';
      }
    });
  }

  btnClass(btnId: string) {
    return this.btnSelected === btnId ? 'blue--btn' : '';
  }

  msgSelected(btnId: string) {
    this.btnSelected = btnId;
    this.msgTone = this.amplifyMsgTones[this.btnSelected];
    this.regenerateAmplifyTxt();
  }

  async regenerateAmplifyTxt() {
    this.loading = true;
    if (!this.msgTone) {
      this.msgTone = this.amplifyMsgTones[this.btnSelected];
    }
    let params = {
      messageTone: this.msgTone,
      textToEdit: this.aiMessage,
      language: this.lang,
    };
    if (this.companyCoreValue !== '') {
      params['companyCoreValue'] = this.companyCoreValue;
    }
    await this.api.regenerateAmplifyTxt(params).then((res) => {
      if (res) {
        this.formattedAiMsg = res?.ai_message;
        this.loading = false;
      }
    });
  }

  useAmplifiedTxt() {
    let desc = this.formattedAiMsg;
    this.dialogRef.close({ desc });
  }
}
