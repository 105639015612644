<input #uploader id="uploader" hidden type="file" accept=".png,.jpeg,.jpg,image/png,image/jpeg"
  (change)="fileChangeEvent($event)" />
<div *ngIf="!confirmImage" [ngClass]="data?.dialogMode ? 'box--top' : ''">
  <div class="box--content" [ngClass]="data?.dialogMode ? 'dialog-mode' : ''">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <div class="txt--title" [ngClass]="data?.dialogMode ? '' : 'd-none'">
        {{ 'common.addImage' | translate }}
      </div>
      <img class="cross--icon" src="assets/img/cross.svg" [mat-dialog-close] />
    </div>
    <div class="col-auto d-flex flex-column gap-2">
      <image-cropper class="box--img" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="false"
        [canvasRotation]="canvasRotation" [transform]="transform" [aspectRatio]="4 / 3" format="png"
        (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()"></image-cropper>
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <a title="Flip Horizontal" (click)="flipHorizontal()">
            <img src="assets/img/icon-filp.svg" />
          </a>
          <a title="{{ 'common.leftRotate' | translate }}" (click)="rotateLeft()">
            <img src="assets/img/icon-rotate-left.svg" />
          </a>
          <a title="{{ 'common.rightRotate' | translate }}" (click)="rotateRight()">
            <img src="assets/img/icon-rotate-right.svg" />
          </a>
        </div>
        <div class="d-flex btn--action">
          <button type="button" class="btn btn-sm btn--img-action" (click)="saveCroppedImage()"
            [disabled]="saveBtnDisabled">
            {{ 'common.cropImage' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="data?.dialogMode" class="box--action">
    <div *ngIf="!!uploadedFiles.length" class="btn--post" [ngClass]="disabledClass" (click)="proceed()">
      {{ 'common.preview' | translate }}
    </div>
    <div *ngIf="!uploadedFiles.length" class="btn--post disabled">
      {{ 'common.preview' | translate }}
    </div>
  </div>
  <div *ngIf="!data?.dialogMode" class="box--action">
    <div class="btn--post" [ngClass]="disabledClass" (click)="proceed()">
      {{ 'common.preview' | translate }}
    </div>
  </div>
</div>
<div *ngIf="confirmImage" [ngClass]="data?.dialogMode ? 'box--top' : ''">
  <div class="box--content" [ngClass]="data?.dialogMode ? 'dialog-mode' : ''">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <div class="txt--title" [ngClass]="data?.dialogMode ? '' : 'display-none'">
        {{ 'common.confirmImage' | translate }}
      </div>
      <img class="cross--icon" src="assets/img/cross.svg" [mat-dialog-close] />
    </div>
    <div class="col-auto d-flex flex-column gap-2">
      <img [src]="uploadedFiles[0].imgBase64" class="box--img" alt="Preview image" />
    </div>
  </div>
  <div *ngIf="data?.dialogMode" class="box--action">
    <div class="btn--back" (click)="backToCropper()">
      {{ 'common.cancel' | translate }}
    </div>
    <div *ngIf="!!uploadedFiles.length" class="btn--post" [ngClass]="disabledClass" [mat-dialog-close]="uploadedFiles"
      (click)="confirmImageNProceed()">
      {{ 'common.proceed' | translate }}
    </div>
    <div *ngIf="!uploadedFiles.length" class="btn--post disabled">
      {{ 'common.proceed' | translate }}
    </div>
  </div>
  <div *ngIf="!data?.dialogMode" class="box--action">
    <div class="btn--back" (click)="goBack.emit()">
      {{ 'common.cancel' | translate }}
    </div>
    <div class="btn--post" [ngClass]="disabledClass" (click)="confirmImageNProceed()">
      {{ 'common.proceed' | translate }}
    </div>
  </div>
</div>