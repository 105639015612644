export default {
  dev: {
    admin: 'https://skordev.com/admin-dashboard/#/report',
    app: 'https://skordev.com/',
    basePath: 'https://dib-pwa.skordev.com/#/',
    img: 'https://skordev.com',
    SSOUrl: 'https://skordev.com/profiles/sso/dib/pwa-sso',
  },
  prod: {
    admin: 'https://dib.cerrapoints.ae/admin-dashboard/#/report',
    app: 'https://cerrapoints.ae/',
    basePath: 'https://dib-pwa.cerrapoints.ae/#/',
    img: 'https://cerrapoints.ae',
    SSOUrl: 'https://cerrapoints.ae/profiles/sso/dib/pwa-sso',
  },
};
