<div [ngClass]="data?.dialogMode ? 'box--top' : ''">
  <div class="txt--title">
    {{ "common.addGIF" | translate }}
  </div>
  <div class="box--search">
    <app-search-box
      [(key)]="key"
      (keyup.enter)="searchImages()"
      (search)="searchImages()"
    ></app-search-box>
  </div>
  <div class="row g-0 box--gifs">
    <div
      *ngFor="let img of gifs"
      class="col-auto box--gif"
      [ngClass]="selectedClass(img)"
    >
      <div class="div--select-mark" [ngClass]="selectedClass(img)">✓</div>
      <img
        class="img-fluid img--gif"
        [ngClass]="selectedClass(img)"
        [src]="img.images.fixed_height_small.url"
        (click)="gif = img"
      />
    </div>
  </div>
  <div *ngIf="data?.dialogMode" class="box--action">
    <div class="btn--back" [mat-dialog-close] (click)="onGoBack()">
      {{ "common.back" | translate }}
    </div>
    <div *ngIf="!gif" class="btn--post disabled">
      {{ "common.proceed" | translate }}
    </div>
    <div
      *ngIf="!!gif"
      class="btn--post"
      [ngClass]="disabledClass"
      [mat-dialog-close]="gif"
      (click)="continue()"
    >
      {{ "common.proceed" | translate }}
    </div>
  </div>
  <div *ngIf="!data?.dialogMode" class="box--action">
    <div class="btn--back" (click)="onGoBack()">
      {{ "common.back" | translate }}
    </div>
    <div class="btn--post" [ngClass]="disabledClass" (click)="continue()">
      {{ "common.proceed" | translate }}
    </div>
  </div>
</div>
