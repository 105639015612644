<div class="d-flex">
  <div class="div--item mr-20 placeholder-glow">
    <div class="attachment--container">
      <span class="attachment w-100 h-100 placeholder"></span>
    </div>
  </div>
  <div class="div--item w-100 placeholder-glow">
    <div class="details--container w-100">
      <span class="details--title placeholder"></span>
      <div *ngFor="let i of items[0]">
        <div class="d-flex justify-content-center item--row">
          <span class="item placeholder"></span>
          <span class="item placeholder"></span>
        </div>
      </div>
    </div>
  </div>
</div>